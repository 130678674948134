import React, { useRef } from "react"

import { Layout } from "@components/layout"
import { Gallery2018 } from "@components/standard"

const Gallery2018Page = ({ pageContext, location }) => {
  const { page } = pageContext
  return (
    <Layout {...page}>
      <Gallery2018 />
    </Layout>
  )
}

export default Gallery2018Page
